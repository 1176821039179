<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'unitoftheday-help',
    	props: {
    		serviceid: Number
    	},

    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		//var serviceId;
    		//let el = document.querySelector("[serviceid]");

    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			console.log("service id is==>" + this.serviceid);
    			if (this.serviceid == 50486) {
    				helpText = 'Hygienische Bettwäsche (Laken, Kissenbezug, Bettbezug) pro Person.';
    			} else if (this.serviceid == 50487) {
    				helpText = 'Hygienische Handtücher (2-mal Duschtücher, 1-mal Händehandtücher) pro Person.';
    			} else if (this.serviceid == 50078) {
    				helpText = 'Cityrad unisex';
    			} else if (this.serviceid == 50077) {
    				helpText = 'Strandkorb am Kurstrand von Laboe (April - September).';
    			} else if (this.serviceid == 50044) {
    				helpText = 'Kinderreisebett inklusive Matratze, Kopfkissen, Decke, sowie passenden Bezügen.';
    			} else if (this.serviceid == 50079) {
    				helpText = 'Erweitertes Teller-Frühstück in einem benachbarten Restaurant.';
    			} else if (this.serviceid == 50236) {
    				helpText = 'Erweitertes Teller-Frühstück in einem benachbarten Restaurant.';
    			} else if (this.serviceid == 49527) {
    				helpText = 'Für die besonderen Reinigungsanforderungen, wenn Du einen Urlaub mit deinem Haustier bei uns verbracht hast.';
    			} else if (this.serviceid == 50114) {
    				helpText = 'Frisch hygienisch gereinigte Bettwäsche (Laken, Kissenbezug, Bettbezug) und Handtücher (Handtuch, Duschtuch) pro Person.';
    			} else if (this.serviceid == 50115) {
    				helpText = 'Buche dieses Extra, falls Du mit mehreren Fahrzeugen anreist. Zum Ausladen fährst Du direkt an den Steg. Dein Fahrzeug parkst Du auf unserem umzäunten Parkplatz im 4 km entfernten Priborn. Unser Shuttle-Service bringt Dich kostenfrei hin & zurück.';
    			} else if (this.serviceid == 50116) {
    				helpText = 'Berechtigung zum Führen von führerscheinpflichtigen Booten für die Reisedauer, wenn keine Person einen Sportbootführerschein besitzt. Notwendig für Boote < 15 PS. Dreistündige, praktische & theoretische Einweisung. Beginnt um 13 Uhr am Anreisetag.';
    			} else if (this.serviceid == 50117) {
    				helpText = 'Du brauchst das nur einmal zu buchen, auch wenn Du mehrere Tiere mitbringst. Für die besonderen Reinigungsanforderungen, wenn Dein Haustier an Bord war.';
    			} else if (this.serviceid == 50118) {
    				helpText = 'Zusätzlich 200€ Kaution - Nutze unsere E-Scooter mit Straßenzulassung um auch bei Landgängen mobil zu sein. Bitte beachte, dass das Mindestalter zum Fahren eines Elektro-Tretrollers (Elektrokleinstfahrzeug) bei 14 Jahren liegt.';
    			} else if (this.serviceid == 50119) {
    				helpText = 'Zusätzlich 100€ Kaution - Mithilfe des Fernglases kannst Du nicht nur die Verkehrszeichen schon aus größerer Entfernung gut erkennen, auch die Natur entlang der ausgedehnten Wasserwege lässt sich damit besser beobachten.';
    			} else if (this.serviceid == 50120) {
    				helpText = 'zusätzlich 100€ Kaution - Sport, Spaß und Erholung bringt der Wassertrendsport Stand-Up-Paddling. Miete eines oder mehrere unserer SUP`s und nimm diese mit auf Dein Hausboot.';
    			} else if (this.serviceid == 50121) {
    				helpText = 'Zum Ausladen fährst Du direkt an den Steg. Je nach Verfügbarkeit gibt es einen Parkplatz in 150 m Entfernung oder Du parkst auf unserem umzäunten Parkplatz im 4 km entfernten Priborn. Unser Shuttle-Service bringt Dich kostenfrei hin & zurück.';
    			} else if (this.serviceid == 50122) {
    				helpText = 'Für den Service sowie den möglichen Aufenthalt im Hafen für die erste und die letzte Nacht berechnen wir diese Servicepauschale.';
    			} else if (this.serviceid == 50124) {
    				helpText = 'Meer Zeit zum Entspannen! Das Wellness-Paket beinhaltet die Nutzung unserer Sauna, ein Sauna-Tuch, sowie einen Bademantel.';
    			}
    			var helpText;
    			return helpText;
    		}
    	}
    };
</script>